<template>Hey pdf</template>

<script>
import HelloSign from "hellosign-embedded";

const client = new HelloSign({
  clientId: "f078869176519d71899ea696680b7c06",
});

client.open(
  "https://app.hellosign.com/editor/embeddedSign?signature_id=e95d60a88bc34c5537aa36b822aab798&token=f8c7a28bac613965d7a7af24fc804380",
  {
    skipDomainVerification: true,
    testMode: true,
  }
);
export default {};
</script>

<style>
</style>